import moment from "moment";
import { auth } from "../services/";

export default {
  install: (app) => {
    app.mixin({
      data: function () {
        return {
          DATE_TIME_HOUR_FORMAT_MOMENT: "YYYY-MM-DD HH:mm",
          FULL_DATE_TIME_FORMAT_MOMENT: "YYYY-MM-DD HH:mm:ss",
          DATE_FORMAT_SCHEDULE: "YYYY-MM-DD",
          HOUR_FORMAT: "HH:mm",
          COLOR_SCHEDULE_ONGOING: "#FCD5B5",
          COLOR_SCHEDULE_PENDING: "#F79646",
          MAX_MONTH_SELECT: 2,
          GROUP_NAME: {
            "Indoor Show Court": "indoor_show_court",
            "Indoor Practice Court": "indoor_practice_court",
            "Outdoor Tennis Court": "outdoor_tennis_court",
            "Junior Court": "junior",
            "Rooftop Court": "rooftop_court",
          },
          STATUS_DISABLED_DATE_SCHEDULE: ['On-going', 'Completed', 'Pending'],
          HIGH_LIGHT_HOUR: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
          TIMEOUT_UPDATE_COURT: 5000,
          SUPER_ADMIN_ROLE: 'Superadmin'
        };
      },
      methods: {
        Empty: function (value) {
          return (
            value == undefined ||
            value == null ||
            value == "" ||
            value.length === 0 ||
            Object.keys(value).length === 0
          );
        },
        MOMENT: function (dateTime, format) {
          if (typeof format !== "undefined" && format) {
            return moment(dateTime).format(format);
          }
          return moment(dateTime);
        },
        NOW_DATE: function () {
          return moment();
        },
        handelGroupModes(data) {
          let dataGroup = {
            "Indoor Show Court": [],
            "Indoor Practice Court": [],
            "Outdoor Tennis Court": [],
            "Junior Court": [],
            "Rooftop Court": [],
          };
          for (var item of data) {
            if (item.group == "Indoor Show Court") {
              dataGroup["Indoor Show Court"].push(item.name);
            } else if (item.group == "Indoor Practice Court") {
              dataGroup["Indoor Practice Court"].push(item.name);
            } else if (item.group == "Outdoor Tennis Court") {
              dataGroup["Outdoor Tennis Court"].push(item.name);
            } else if (item.group == "Junior Court") {
              dataGroup["Junior Court"].push(item.name);
            } else if (item.group == "Rooftop Court") {
              dataGroup["Rooftop Court"].push(item.name);
            }
          }
          return dataGroup;
        },
        handelGroupCourts(group_mode, courts) {
          let dataGroup = {
            indoor_show_court: [],
            indoor_practice_court: [],
            outdoor_tennis_court: [],
            junior: [],
            rooftop_court: [],
          };
          for (var item of courts) {
            item.modes = group_mode[item.group];
            if (item.group == "Indoor Show Court") {
              dataGroup.indoor_show_court.push(item);
            } else if (item.group == "Indoor Practice Court") {
              dataGroup.indoor_practice_court.push(item);
            } else if (item.group == "Outdoor Tennis Court") {
              dataGroup.outdoor_tennis_court.push(item);
            } else if (item.group == "Junior Court") {
              dataGroup.junior.push(item);
            } else if (item.group == "Rooftop Court") {
              dataGroup.rooftop_court.push(item);
            }
          }
          return dataGroup;
        },
        renderIcon(show, tag, classShow, classElse) {
          return `<${tag} class='${show ? classShow : classElse}'></${tag}>`;
        },
        renderIconDropdown(tag, classIcon) {
          return `<${tag} class='${classIcon}'></${tag}>`;
        },
        async getVersion() {
          let version = "Sports Smart Lighting Solution Ver.2.0.1 ©2023";
          await auth.getVersion().then((res) => {
            if (res.status == 200 && res.data.success) {
              version =
                "Sports Smart Lighting Solution Ver." +
                res.data.data +
                " ©" +
                moment().format("Y");
            }
          });
          return version;
        },
        getDaysBetween(start, end) {
          var now = start.clone(),
            dates = [];
          while (now.isSameOrBefore(end)) {
            dates.push(now.clone());
            now.add(1, "days");
          }
          return dates;
        },
        padTo2Digits(num) {
          return String(num).padStart(2, '0');
        },
        getHeightRowGranttChart() {
          let height_row = 68;
          if (window.innerWidth == 1920 && window.innerHeight == 1080) {
            height_row = 70;
          }
          else if (window.innerWidth <= 1180 && window.innerHeight == 820 || window.innerWidth <= 1920 && window.innerHeight <= 976) {
            height_row = 50;
          }
          else if (window.innerHeight > 1080) {
            if (window.innerWidth == 2048 && window.innerHeight == 1536) {
              height_row = 110;
            } else {
              if (window.innerWidth == 3440 && window.innerHeight == 1440) {
                height_row = 105;
              } else {
                height_row =
                  68 * (window.innerHeight / 1080) +
                  (window.innerHeight - 1080) / 68;
              }
            }
          }
          return height_row;
        },
      },
    });
  },
};
