const data = [
  {
    id: "ISC_IS1",
    group: "Indoor Show Court",
    name: "IS1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD B CASRT",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IS2",
    group: "Indoor Show Court",
    name: "IS2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD B CASRT",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "12",
    power_consumption: 0,
  },
  {
    id: "ISC_IS3",
    group: "Indoor Show Court",
    name: "IS3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD B CASRT",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "",
    power_consumption: 0,
  },
  {
    id: "ISC_IP1",
    group: "Indoor Practice Court",
    name: "IP1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP2",
    group: "Indoor Practice Court",
    name: "IP2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP3",
    group: "Indoor Practice Court",
    name: "IP3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP4",
    group: "Indoor Practice Court",
    name: "IP4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_01",
    group: "Outdoor Tennis Court",
    name: "01",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_02",
    group: "Outdoor Tennis Court",
    name: "02",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_03",
    group: "Outdoor Tennis Court",
    name: "03",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_04",
    group: "Outdoor Tennis Court",
    name: "04",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "15",
    power_consumption: 0,
  },
  {
    id: "ISC_05",
    group: "Outdoor Tennis Court",
    name: "05",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_06",
    group: "Outdoor Tennis Court",
    name: "06",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_07",
    group: "Outdoor Tennis Court",
    name: "07",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_08",
    group: "Outdoor Tennis Court",
    name: "08",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 0,
    expected_on: 1,
    scheduled: 1,
    online: 0,
    led: "",
    power_consumption: 0,
  },
  {
    id: "ISC_J1",
    group: "Junior Court",
    name: "J1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_J2",
    group: "Junior Court",
    name: "J2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 0,
    led: "",
    power_consumption: 0,
  },
  {
    id: "ISC_R1",
    group: "Rooftop Court",
    name: "R1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R2",
    group: "Rooftop Court",
    name: "R2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R3",
    group: "Rooftop Court",
    name: "R3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R4",
    group: "Rooftop Court",
    name: "R4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const indoor_show_court = [
  {
    id: "ISC_IS1",
    group: "Indoor Show Court",
    name: "IS1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD B CASRT",
    expected_mode: "HD B CASRT",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR", "HD B CASRT"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IS2",
    group: "Indoor Show Court",
    name: "IS2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD B CASRT",
    expected_mode: "HD B CASRT",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR", "HD B CASRT"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IS3",
    group: "Indoor Show Court",
    name: "IS3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD B CASRT",
    expected_mode: "HD B CASRT",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR", "HD B CASRT"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const indoor_practice_court = [
  {
    id: "ISC_IP1",
    group: "Indoor Practice Court",
    name: "IP1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP2",
    group: "Indoor Practice Court",
    name: "IP2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP3",
    group: "Indoor Practice Court",
    name: "IP3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP4",
    group: "Indoor Practice Court",
    name: "IP4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const outdoor_tennis_court = [
  {
    id: "ISC_01",
    group: "Outdoor Tennis Court",
    name: "01",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_02",
    group: "Outdoor Tennis Court",
    name: "02",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_03",
    group: "Outdoor Tennis Court",
    name: "03",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_04",
    group: "Outdoor Tennis Court",
    name: "04",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_05",
    group: "Outdoor Tennis Court",
    name: "05",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_06",
    group: "Outdoor Tennis Court",
    name: "06",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_07",
    group: "Outdoor Tennis Court",
    name: "07",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_08",
    group: "Outdoor Tennis Court",
    name: "08",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 0,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const junior = [
  {
    id: "ISC_J1",
    group: "Junior Court",
    name: "J1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "15",
    power_consumption: 0,
  },
  {
    id: "ISC_J2",
    group: "Junior Court",
    name: "J2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const rooftop_court = [
  {
    id: "ISC_R1",
    group: "Rooftop Court",
    name: "R1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R2",
    group: "Rooftop Court",
    name: "R2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R3",
    group: "Rooftop Court",
    name: "R3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R4",
    group: "Rooftop Court",
    name: "R4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const courts_data = {
  data,
  indoor_show_court,
  indoor_practice_court,
  outdoor_tennis_court,
  junior,
  rooftop_court,
};

export default courts_data;
