import { courtService } from "../../services";
import courts_data_default from "@/components/courts_data";
import modes_data from "@/components/modes_data";

async function getModes() {
  let listMode = modes_data.data;
  await courtService.getListModes().then((res) => {
    if (res.status == 200 && res.data.success) {
      listMode = res.data.data;
    }
  });
  return listMode;
}

async function getCourts() {
  let courtData = courts_data_default.data;
  await courtService.getListCourts().then((res) => {
    if (res.status == 200 && res.data.success) {
      courtData = res.data.data;
    }
  });
  return courtData;
}

const courts = {
  namespaced: true,
  state: {
    modes: [],
    courts: [],
    courtsGroup: courts_data_default,
    listWaitingOnOff: [],
  },
  actions: {
    async getModes({ commit }) {
      let modes = await getModes();
      commit("setModes", modes);
    },
    async getCourts({ commit }) {
      let courts = await getCourts();
      
      commit("setCourts", courts);
    },
    setCourtsGroup({ commit }, data) {
      commit("setCourtsGroup", data);
    },
    updatedCourt({ commit }, data) {
      commit("updatedCourt", data);
    },
    pushFilterCourt({ commit }, data) {
      commit("pushFilterCourt", data);
    },
    removeFilterCourt({ commit }, data) {
      commit("removeFilterCourt", data);
    },
  },
  mutations: {
    setModes(state, modes) {
      state.modes = modes;
    },
    setCourts(state, courts) {
      let dataClone = JSON.parse(JSON.stringify(state.courts));
      dataClone = courts;
      state.courts = dataClone;
    },
    setCourtsGroup(state, data) {
      state.courtsGroup = data;
    },
    updatedCourt(state, data) {
      let index_group = state.courtsGroup[data.nameCourtsGroup].filter((item) => {
        item.id == data.currentData.id;
      });
      if (index_group >= 0 && state.courtsGroup[data.nameCourtsGroup][index_group]) {
        state.courtsGroup[data.nameCourtsGroup][data.index] = data.currentData;
      }
      let index = state.courts.filter((item) => {
        item.id == data.currentData.id;
      });
      if (index >= 0 && state.courts[index]) {
        state.courts[index] = data.currentData;
      }
    },
    pushFilterCourt(state, data) {
      state.listWaitingOnOff.push(data);
    },
    removeFilterCourt(state, index) {
      state.listWaitingOnOff = state.listWaitingOnOff.filter((item, inx) => { return inx != index});
    },
  },
  getters: {
    getModes(state) {
      return state.modes;
    },
    getCourts(state) {
      return state.courts;
    },
    getCourtsGroup(state) {
      return state.courtsGroup;
    },
  },
};
export default courts;
