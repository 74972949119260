<template>
  <v-app class="app-wrapper">
    <BarMenu v-if="$store.state && $store.state.user && $store.state.token && !noAuthRoutes.includes($route.name)"></BarMenu>
    <v-main style="padding: 0">
      <v-container fluid class="p-0">
        <router-view />
        <div class="clearfix"></div>
      </v-container>
    </v-main>
    <FooterComp v-if="!noAuthRoutes.includes($route.name)"/>
  </v-app>
</template>

<style src="./assets/css/styles.css"></style>

<script>
import BarMenu from "@/components/BarMenu.vue";
import FooterComp from "@/components/FooterComp";
import { noAuthRoutes } from '@/constants';

export default {
  name: "App",
  data() {
    return {
      noAuthRoutes: noAuthRoutes
    };
  },
  components: { BarMenu, FooterComp },
};
</script>
