<template>
  <footer FooterComp v-if="isLoggedIn">
    <div class="der-footer">
      {{ info_footer }}
    </div>
    <div class="d-flex justify-content-center footer-content">
      <h5 style="color: #b5b5b5">
        <span style="color: #ffffff"
          ><span style="color: #ffffff; font-weight: bold">Powered by </span
          >Panasonic</span
        >
      </h5>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  data() {
    return {
      info_footer: "Sports Smart Lighting Solution Ver.0.0.1 ©2022",
    };
  },
  async created() {
    this.info_footer = await this.getVersion();
  },
};
</script>

<style scoped>
.footer-content {
  padding-top: 20px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1200px) {
  h5,
  .h5 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .der-footer {
    font-size: 14px;
  }

  .footer-content {
    padding-top: 0;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 1610px) and (max-width: 2490px) {
  .footer-content {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 1920px) {
  .footer-content h5 {
    margin-bottom: 0;
    padding-bottom: 5px;
  }
}
</style>
