import HTTP_SERVICE from './http-service';

export default {
    getListSchedules(params) {
        return HTTP_SERVICE.get('/schedules' + (params ?? ''));
    },
    getSchedule(id) {
        return HTTP_SERVICE.get(`/schedules/${id}`);
    },
    storeSchedule(data) {
        return HTTP_SERVICE.post('/schedules', data);
    },
    updateSchedule(id, data) {
        return HTTP_SERVICE.put(`/schedules/${id}`, data);
    },
    deleteSchedule(id) {
        return HTTP_SERVICE.delete(`/schedules/${id}`);
    }
}