export const accessibleMenus = {
  dashboard: 'Dashboard',
  schedule: 'Schedule',
  user: 'Users',
  setting: 'Setting',
  log: 'Log',
}

export const noAuthRoutes = [
  'Login',
  'Change Password',
  'Forgot Password',
  '404'
]

export const footerProps = {
  disableItemsPerPage: true,
  itemsPerPageText: 'Showing',
  firstIcon: 'mdi-arrow-collapse-left',
  lastIcon: 'mdi-arrow-collapse-right',
  prevIcon: 'mdi-menu-left',
  nextIcon: 'mdi-menu-right',
  pageText: ''
}