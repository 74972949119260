<template>
  <div id="profile-wrapper">
    <v-row no-gutters>
      <v-col cols="12" class="main-content">
        <div>
          <v-row id="top-form" class="box-height1" no-gutters>
            <v-col cols="6" class="first-col">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p>User Name</p>
                    </v-col>
                    <v-col cols="12">
                      <p class="color-green">{{ userName }}</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12 pt-3">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p>Last Logged In</p>
                    </v-col>
                    <v-col cols="12">
                      <p class="color-green">{{ lastLogin }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" class="second-col">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters><p>Email</p></v-row>
                <v-row no-gutters
                  ><v-text-field
                    v-model="emailAddress"
                    :rules="emailRules"
                    filled
                    style="width: 100% !important"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters> <p>Password</p> </v-row>
                <v-row no-gutters align="center">
                  <v-col cols="3" style="padding-top: 8px">
                    <span class="hidden-pass">******</span>
                  </v-col>
                  <v-col cols="9" class="text-right btn-change-password">
                    <v-btn
                      :ripple="false"
                      class="form-btn"
                      style="height: 40px; width: auto"
                      @click="modalChangePassword = true"
                    >
                      Change Password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>

            <v-col cols="12 pt-4" class="second-col">
              <v-row no-gutters class="text-right">
                <v-col cols="12">
                  <button
                    class="btn btn-secondary btn-custom"
                    :ripple="false"
                    @click="closeProfile()"
                  >
                    Cancel
                  </button>
                  <button
                    class="ml-4 btn btn-primary btn-custom"
                    :disabled="!valid"
                    :ripple="false"
                    @click="submitUpdate"
                  >
                    Save
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button
                class="btn btn-primary btn-custom"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalChangePassword"
      max-width="500"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="dialog-change-password">
        <v-card-title>Change Password</v-card-title>
        <v-card-text
          >
          <v-form
            ref="formChangePassword"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters class="fill-height">
                  <v-col cols="12">
                    <v-row no-gutters class="half-height">
                      <v-col cols="12">
                        <v-row no-gutters><p>Old Password</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            v-model="oldPassword"
                            required
                            :type="showOldPass ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="passwordRules"
                            outlined
                            filled
                          >
                            <template v-slot:append>
                              <a
                                style="margin-top: 2px"
                                @click="showOldPass = !showOldPass"
                                v-html="
                                  renderIcon(
                                    showOldPass,
                                    'i',
                                    'fas fa-eye',
                                    'fas fa-eye-slash'
                                  )
                                "
                              >
                              </a>
                            </template>
                          </v-text-field>
                        </v-row>
                      </v-col>
                      <v-col cols="12 pt-1">
                        <v-row no-gutters><p>New Password</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            v-model="newPassword"
                            required
                            :type="showNewPass ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="passwordRules"
                            outlined
                            filled
                          >
                            <template v-slot:append>
                              <a
                                style="margin-top: 2px"
                                @click="showNewPass = !showNewPass"
                                v-html="
                                  renderIcon(
                                    showNewPass,
                                    'i',
                                    'fas fa-eye',
                                    'fas fa-eye-slash'
                                  )
                                "
                              >
                              </a>
                            </template>
                          </v-text-field>
                        </v-row>
                      </v-col>
                      <v-col cols="12 pt-1">
                        <v-row no-gutters><p>Confirm Password</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            v-model="ConfirmPassword"
                            required
                            :type="showConfirmPassword ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="confirmPassRules"
                            outlined
                            filled
                          >
                            <template v-slot:append>
                              <a
                                style="margin-top: 2px"
                                @click="
                                  showConfirmPassword = !showConfirmPassword
                                "
                                v-html="
                                  renderIcon(
                                    showConfirmPassword,
                                    'i',
                                    'fas fa-eye',
                                    'fas fa-eye-slash'
                                  )
                                "
                              >
                              </a>
                            </template>
                          </v-text-field>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12 pt-4" class="text-right">
                <button
                  class="btn btn-secondary btn-custom"
                  type="button"
                  @click="modalChangePassword = false"
                >
                  Cancel
                </button>
                <button
                  class="ml-4 btn btn-primary btn-custom"
                  type="submit"
                >
                  Save
                </button>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" src="@/assets/css/user-profile.scss"></style>
<script>
import moment from "moment";
import { userProfileService } from "../services";
export default {
  data() {
    return {
      userName: "",
      userGroup: "",
      userGroupId: "",
      userSite: "",
      lastLogin: "",
      emailAddress: "",
      oldPassword: "",
      newPassword: "",
      ConfirmPassword: "",
      dialog: false,
      showOldPass: false,
      showNewPass: false,
      showConfirmPassword: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v?.length >= 6 || "Password length must be greater than or equal to 6",
      ],
      userProfile: {},
      valid: false,
      editValid: false,
      fileRule: [
        (value) =>
          !value ||
          value.size <= 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      modalChangePassword: false,
    };
  },
  async created() {
    await userProfileService
      .getCurrentUserProfile()
      .then((res) => {
        if (res.status == 200 && res?.data?.data) {
          this.setUserProfile(res.data.data);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          this.apiMessage = err.response.data.message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        }
      });
  },
  computed: {
    confirmPassRules() {
      return [
        this.ConfirmPassword === this.newPassword || "Password must match",
      ];
    },
  },
  methods: {
    setUserProfile(data) {
      this.userName = data.username || "";
      this.userGroup = data.user_group?.name || "";
      this.userGroupId = data.user_group_id || "";
      this.userSite = data.user_site || "N/A";
      this.valid = !!data.email;
      this.lastLogin = data.last_login
        ? moment(data.lastLogin).format("DD-MM-YYY HH:mm:ss")
        : "Active Now";
      this.emailAddress = data.email || "";
      this.userProfile = Object.assign({}, this.userProfile, data);
    },
    revert() {
      this.setUserProfile(this.userProfile);
      this.showOldPass = false;
      this.showNewPass = false;
      this.showConfirmPassword = false;
    },
    submitUpdate() {
      const data = {
        username: this.userName,
        email: this.emailAddress,
        user_group_id: this.userGroupId,
      };
      return userProfileService
        .setUserProfile(data)
        .then((res) => {
          if (res.status == 200 && res?.data?.data) {
            this.setUserProfile(res.data.data);
            this.apiMessage = "Update profile user successfully";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.$emit("submit-success");
          } else {
            throw res;
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.apiMessage = err.response.data.message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          }
        });
    },
    triggerFileChange() {
      this.$refs.file.$refs.input.click();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.editForm.reset();
    },
    submitEdit() {
      if (this.$refs.formChangePassword.validate()) {
        const data = {
          current_password: this.oldPassword,
          new_password: this.newPassword,
        };
        let vm = this;
        userProfileService
          .updateUserPassword(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            vm.revert();
            vm.apiMessage = "Update password successfully";
            vm.apiTitle = "Success";
            vm.messageDialog = true;
            vm.modalChangePassword = false;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.apiMessage = err.response.data.message;
              this.apiTitle = "Error";
              this.messageDialog = true;
            }
            vm.modalChangePassword = false;
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
    },
    closeProfile() {
      this.$emit("submit-success");
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
