import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API + "/api/v1",
  timeout: 5000
});

if (localStorage.getItem('token')) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  instance.defaults.headers.common["Content-Type"] = "application/json";
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      if (location.pathname != "/login") {
        location.reload();
      }
    }
    return error;
  }
);

export default {
  instance,
  get(url) {
    return instance.get(url);
  },
  post(url, params) {
    return instance.post(url, params);
  },
  put(url, params) {
    return instance.put(url, params);
  },
  delete(url) {
    return instance.delete(url);
  }
}