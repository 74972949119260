<template>
  <v-app-bar app v-if="isLoggedIn">
    <div class="d-flex align-center">
      <a href="/">
        <v-img
          alt="Panasonic Logo"
          class="nav-logo"
          contain
          src="@/assets/logo-KTC.png"
        />
      </a>
    </div>
    <div class="app-header-spacing"></div>
    <template>
      <div class="menu-wrapper">
        <v-row no-gutters>
          <v-spacer />
          <div
            class=""
            v-if="accessibleRoutes.dashboard"
            style="margin-right: 5px"
          >
            <div class="pd-left">
              <router-link :to="{ path: '/' }">
                <v-btn
                  :ripple="false"
                  icon
                  @click="active = 'home'"
                  :class="{ active: getActive() === 'home' }"
                >
                  DASHBOARD
                </v-btn>
              </router-link>
            </div>
          </div>
          <div
            v-if="accessibleRoutes.schedule"
            class=""
            style="margin-right: 5px"
          >
            <div class="pd-left">
              <router-link :to="{ path: '/schedule' }">
                <v-btn
                  :ripple="false"
                  icon
                  @click="active = 'schedule'"
                  :class="{ active: getActive() === 'schedule' }"
                >
                  SCHEDULE
                </v-btn>
              </router-link>
            </div>
          </div>
          <div style="max-width: 80px">
            <div class="pd-left" style="margin-right: 5px">
              <div class="menu-wrapper-mobile">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      type="button"
                      class="burger-button"
                      v-bind="attrs"
                      v-on="on"
                      title="Menu"
                    >
                    </v-btn>
                  </template>
                  <v-list
                    class="bar-menu-dropdown"
                    style="background-image: none !important"
                  >
                    <v-list-item v-if="accessibleRoutes.user">
                      <router-link :to="{ path: '/users/list' }">
                        <span>
                          <i class="fa-solid fas fa-user-cog mr-2"></i>User
                        </span>
                      </router-link>
                    </v-list-item>
                    <v-list-item v-if="accessibleRoutes.setting">
                      <router-link :to="{ path: '/settings' }">
                        <span>
                          <i class="fa-solid fa-gear mr-2"></i>Dimming Setting
                        </span>
                      </router-link>
                    </v-list-item>
                    <v-list-item v-if="accessibleRoutes.log">
                      <router-link :to="{ path: '/logs/devices' }">
                        <span>
                          <button class="icon-logs mr-2"></button>History Log
                        </span>
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <a @click="modalChangePassword = true">
                        <span>
                          <i class="fa-solid fas fa-user mr-2"></i>My Profile
                        </span>
                      </a>
                    </v-list-item>
                    <v-list-item>
                      <a :ripple="false" @click="logout">
                        <span>
                          <i class="fa-solid fa-right-from-bracket mr-2"></i
                          >Logout
                        </span>
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-row>
      </div>
      <v-dialog
        v-model="modalChangePassword"
        persistent
        max-width="700"
        content-class="profile-confirm-dialog"
      >
        <v-card id="profile-confirm">
          <v-card-title>User Profile</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12">
                <UserProfile @submit-success="closeModal"></UserProfile>
              </v-col> </v-row
          ></v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-app-bar>
</template>

<script>
import { auth } from "@/services";
import UserProfile from "@/components/UserProfile.vue";

export default {
  name: "App",
  components: { UserProfile },
  data() {
    return {
      active: "home",
      nowTime: "",
      nowDate: "",
      isBurgerActive: false,
      modalChangePassword: false,
    };
  },
  methods: {
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
    },
    logout() {
      auth.logout().finally(() => {
        this.$store.dispatch("logout").then(() => location.reload());
      });
    },
    getActive() {
      switch (window.location.pathname) {
        case "/":
          return "home";
        case "/users/list":
        case "/users/groups":
          return "user";
        case "/schedule":
          return "schedule";
        case "/settings":
          return "settings";
        case "/logs":
          return "logs";
        default:
          return "home";
      }
    },
    closeModal() {
      this.modalChangePassword = false;
    },
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
  },
};
</script>

<style scoped>
.v-menu__content.menuable__content__active {
  top: 84px !important;
  right: 0;
  left: auto !important;
  min-width: 85px !important;
}

.bar-menu-dropdown {
  padding-left: 10px;
  padding-right: 10px;
}

.bar-menu-dropdown .v-list-item a {
  color: #f7ffc9 !important;
  text-decoration: none;
  font-weight: bold;
}

.bar-menu-dropdown .v-list-item a:hover {
  color: #ecfa9f !important;
}

.bar-menu-dropdown {
  background: none !important;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(0, 0, 0, 1) !important;
  border: none !important;
}

@media only screen and (max-width: 1200px) {
  .nav-logo {
    width: 70%;
    height: 70%;
  }
}
</style>
