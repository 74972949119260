import HTTP_SERVICE from "./http-service";
import axios from 'axios';

export default {
  getListCourts(params = null) {
    return HTTP_SERVICE.get("/courts" + (params ?? ""));
  },
  getListModes(params = null) {
    return HTTP_SERVICE.get("/modes" + (params ?? ""));
  },
  getCourt(id) {
    return HTTP_SERVICE.get(`/courts/${id}`);
  },
  changeCourtMode(id, data) {
    return HTTP_SERVICE.put(`/courts/${id}`, data);
  },
  changeCourtOnOff(id, data) {
    return HTTP_SERVICE.put(`/courts/${id}`, data);
  },
  updateDimmingMode(data) {
    return HTTP_SERVICE.post(`/modes/update-dimming-modes`, data);
  },
  testCourts() {
    return axios.get(process.env.VUE_APP_API + "/api/test-update-courts");
  },
};
