const data = [
    {
        "id": 1,
        "group": "Indoor Show Court",
        "name": "COM",
        "dimming": 15
    },
    {
        "id": 2,
        "group": "Indoor Show Court",
        "name": "CLASS II",
        "dimming": 25
    },
    {
        "id": 3,
        "group": "Indoor Show Court",
        "name": "TOUR",
        "dimming": 55
    },
    {
        "id": 4,
        "group": "Indoor Show Court",
        "name": "HD B CASRT",
        "dimming": 100
    },
    {
        "id": 5,
        "group": "Indoor Practice Court",
        "name": "COM",
        "dimming": 30
    },
    {
        "id": 6,
        "group": "Indoor Practice Court",
        "name": "CLASS II",
        "dimming": 50
    },
    {
        "id": 7,
        "group": "Indoor Practice Court",
        "name": "TOUR",
        "dimming": 100
    },
    {
        "id": 8,
        "group": "Outdoor Tennis Court",
        "name": "CLASS II",
        "dimming": 30
    },
    {
        "id": 9,
        "group": "Outdoor Tennis Court",
        "name": "TOUR",
        "dimming": 100
    },
    {
        "id": 10,
        "group": "Junior Court",
        "name": "CLASS II",
        "dimming": 40
    },
    {
        "id": 11,
        "group": "Junior Court",
        "name": "TOUR",
        "dimming": 100
    },
    {
        "id": 12,
        "group": "Rooftop Court",
        "name": "CLASS II",
        "dimming": 40
    },
    {
        "id": 13,
        "group": "Rooftop Court",
        "name": "TOUR",
        "dimming": 100
    }
];

const group_mode = {
    'indoor_show_court': 'Indoor Show Court',
    'indoor_practice_court': 'Indoor Practice Court',
    'outdoor_tennis_court': 'Outdoor Tennis Court',
    'junior': 'Junior Court',
    'rooftop_court': 'Rooftop Court'
}

export default {
    group_mode,
    data
};